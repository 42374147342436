import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'withdrawal_right';
const namespace = 'ui-pdp-icon ui-pdp-icon--withdrawal-right';

const IconWithdrawalRight = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconWithdrawalRight.propTypes = {
  className: string,
};

IconWithdrawalRight.ICON_ID = ICON_ID;

export default React.memo(IconWithdrawalRight);
export { IconWithdrawalRight };
